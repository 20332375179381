import React, { useEffect } from 'react'
import { Toast } from 'primereact/toast'
import addNotification from 'react-push-notification'
import state from '@Common/State'
import { useHandleNavigate } from '@Common/Services'

const AppNotifications = () => {
  const [messageGrowl] = state.messageGrowl.useState()
  const [visibleGrowl] = state.visibleGrowl.useState()
  const [desktopNotificationVisible, setDesktopNotificationVisible] = state.desktopNotificationVisible.useState()
  const [desktopNotificationActivated] = state.desktopNotificationActivated.useState()
  const navigate = useHandleNavigate()
  useEffect(() => {
    if (visibleGrowl) {
      window.growl.show({
        severity: 'info',
        summary: 'Notifica',
        detail: state.messageGrowl.state
      })
    }
  }, [messageGrowl, visibleGrowl])

  useEffect(() => {
    if (document.hidden && desktopNotificationVisible && desktopNotificationActivated) {
      addNotification({
        title: messageGrowl,
        subtitle: messageGrowl,
        message: state.messageText.state || '',
        onClick: (e) => {
          window.focus()
          navigate('/messages/' + state.newMessageConversation.state)
        },
        native: true
      })
      setDesktopNotificationVisible(false)
    }
  }, [desktopNotificationVisible])

  return (
    <Toast
      ref={(el) => {
        window.growl = el
      }}
      style={{ marginTop: 50 }}
    />
  )
}

export default AppNotifications
